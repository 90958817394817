@import "@Static/css/common";
$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(2, (255, 255, 255));
$color3: getColorValue(3, (51, 51, 51));

.root {
    color: $color2;
    background-color: $color1;
    padding: 3rem 0;
}

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    div {
        word-break: break-word;
    }
}

@media only screen and (max-width: 800px) {
    .container {
        flex-direction: column;
    }
}

.statContainer {
    &:last-of-type {
        border: none;
    }
}

@media only screen and (min-width: 801px) {
    .statContainer {
        padding: 1rem;
        flex-grow: 1;
        flex-basis: 0;
        border-right: 1px solid $color2;
    }
}

@media only screen and (max-width: 800px) {
    .statContainer {
        padding: 1.5rem 0rem;
        width: 100%;
        border-bottom: 1px solid $color2;
    }
    .root {
        padding: 2rem 0;
    }
}
