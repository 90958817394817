@import "@Static/css/common";

$bgColor: getColorValue(2, (255, 255, 255));

.root {
    background-color: $bgColor;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.container {
    position: relative;
}

.title {
    text-align: center;
}

.carouselContainer {
    ul {
        margin: auto;
    }
}

.dotListClass {
    bottom: -2rem;
}
