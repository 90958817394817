@import "@Static/css/common";
$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(2, (255, 255, 255));
$color3: getColorValue(3, (51, 51, 51));

.container {
    color: $color3;
    padding-top: 3rem;
}

@media only screen and (max-width: 450px) {
    .container {
        text-align: center;
        padding-top: 2rem;
        margin-bottom: 36px;
    }
}

.sectionTitle {
    text-align: center;
    margin-bottom: 2rem;
    word-break: break-word;
}

.mainImage {
    object-fit: cover;
    background-color: #f7f7f7;
    border-radius: 4px;
}

@media only screen and (min-width: 981px) {
    .mainImage {
        width: 400px;
        height: 400px;
    }
}

@media only screen and (max-width: 980px) {
    .mainImage {
        width: 100%;
    }
}

.sectionBody {
    display: flex;
}

@media only screen and (max-width: 980px) {
    .sectionBody {
        flex-direction: column;
    }
}

@media only screen and (min-width: 981px) {
    .detailsContainer {
        margin-left: 2rem;
    }
}

@media only screen and (max-width: 980px) {
    .detailsContainer {
        margin-top: 1rem;
    }
}

.detailContainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 450px) {
    .detailContainer {
        flex-direction: column;
        align-items: center;
        img {
            margin-bottom: 16px;
        }
    }
}

.textContainer {
    margin-left: 1rem;
}

.textHeading {
    font-size: 1rem;
    font-weight: 600;
}
@media only screen and (max-width: 450px) {
    .textContainer {
        margin-left: 0 !important;
    }

    .textHeading {
        margin-bottom: 4px;
    }
}

.textBody {
    font-size: 0.8rem;
}
