@import "@Static/css/common";
$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(2, (255, 255, 255));
$color3: getColorValue(3, (51, 51, 51));

.eventSectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.events {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.keyword {
    border: 1px solid $color1;
    color: $color1;
    font-size: 0.9rem;
    font-weight: 500;

    &.selectedKeyword {
        background-color: $color1;
        color: $color2;
    }
}

.loadMore {
    color: $color1;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    margin: 8px 0 -8px;

    transition: 0.2s ease all;
    display: flex;
    align-items: center;

    &:hover {
        transform: scale(1.02);
        text-decoration: underline;
    }

    .loadIcon {
        font-size: 16px;
        margin-top: 4px;
        margin-left: 4px;
    }
}
