@import "@Static/css/common";

$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(1, (56, 36, 242), 0.1);

.root {
    background-color: $color2;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
        max-width: 95vw;
    }
}

.title {
    text-align: center;
    margin-bottom: 1rem;
}

.carouselContainer {
    ul {
        margin: auto;
    }
}

.dotListClass {
    bottom: -2rem;
}

.blogPostsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.blogPostContainer {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
}

.blogLink {
    color: $color1;
}
