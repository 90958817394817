@import "@Static/css/common";
$color1: getColorValue(1, (56, 36, 242));
$color2: getColorValue(2, (255, 255, 255));
$color3: getColorValue(3, (51, 51, 51));
$color4: getColorValue(3, (51, 51, 51), 0.7);

$cardBgColor: rgb(
    var(--template-page-card-background-host-theme, (255, 255, 25))
);

.container {
    background-color: $cardBgColor;
    width: 340px;
    height: 367.5px;
    display: flex;
    flex-direction: column;
    line-height: 1.6;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000014;
    transition: 0.15s ease all;

    cursor: pointer;
    transition: 0.2s ease all;
    position: relative;

    &:hover {
        transform: translateY(-4px) scale(1.01);
        box-shadow: 0px 3px 6px #00000020;
    }

    hr {
        margin: 7px 0;
    }
}

@media only screen and (max-width: 800px) {
    .container {
        margin: 10px 0;
    }
}

@media only screen and (min-width: 801px) {
    .container {
        margin: 20px;
    }
}

.imageContainer {
    position: relative;
}

.image {
    object-fit: cover;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #eee;
}

.labelsContainer {
    position: absolute;
    top: 10px;
    margin-left: 10px;
    display: flex;
    font-size: 0.7rem;
}

.label {
    padding: 2px 6px;
    margin-right: 6px;
}

.detailsContainer {
    flex-grow: 1;
    padding: 12px 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.titleContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    font-size: 0.8rem;
    font-weight: 700;
    color: $color3;
}

.shortDescription {
    font-size: 0.65rem;
    color: $color4;
}

.price {
    font-size: 0.95rem;
    font-weight: 700;
    margin-bottom: 8px;
    color: $color3;
}

.ctaButton {
    width: 100%;

    transition: 0.15s ease all;
    &:hover {
        filter: brightness(1.08);
    }
}
