@import "@Static/css/common";

$primaryColor: getColorValue(1, (47, 106, 103));
$bgColor: getColorValue(2, (255, 255, 255));
$textColor: getColorValue(3, (234, 251, 249));

$cardBgColor: rgb(
    var(--template-page-card-background-host-theme, (255, 255, 25))
);

.root {
    max-width: 359px;
    // TODO: delete if not used till 18th july 2022
    // height: 400px;
    padding: 1rem;
    margin: 30px auto;
    cursor: pointer;
}

.container {
    width: 100%;
    // TODO: delete if not used till 18th july 2022
    // height: 100%;
    background-color: $cardBgColor;
    color: $textColor;
    transition: 0.2s ease all;

    box-shadow: #ccc7 0px 3px 6px;
    &:hover {
        transform: translateY(-4px) scale(1.01);
    }
}
.readMore {
    cursor: pointer;
    color: $bgColor;
}
.image {
    width: 100%;
    height: 256px;
    background: center no-repeat;
    background-size: cover;
}

.detailsContainer {
    padding: 10px;
    font-size: 0.8rem;
}

.name {
    font-weight: 600;
}

.heading {
    font-weight: 400;
    height: 1.6rem;
    overflow-y: auto;
    color: $textColor;
}

.horizontalBar {
    margin: 5px 0;
}

.description {
    max-height: 3.2rem;
    overflow-y: auto;
    line-height: normal;
    font-weight: 400;
}
